<template>
  <div>
    <!-- Sony Bucks Logo -->
    <v-img src="@/assets/sony_bucks_logo.png" height="200" contain pb-2 />
    <!-- Sony Bucks Program Information -->
    <v-card class="program-details-card" flat>
      <v-card-text>
        <div class="text-h5">
          <b>Sony BUCKS</b> program lets you earn dollars on every purchase of eligible Sony Products you make! Sell
          <b>Sony</b> – Earn <b>Bucks</b>!
        </div>
        <v-list class="program-details-text">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="no-ellipsis">1. Register for the program</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="no-ellipsis"
                >2. Purchase eligible product from an Authorized Distributor Account during the promotional
                period</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="no-ellipsis"
                >3. Submit your claim and upload your Distributor’s receipt</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="no-ellipsis">4. Earn Bucks!</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <div class="text-h5 font-weight-bold">
          Please provide your email address to receive more details and instructions to complete your registration.
        </div>
      </v-card-text>
    </v-card>
    <!-- Stepper Logic -->
    <v-stepper v-model="step">
      <v-stepper-header class="hide-header" />
      <v-stepper-items>
        <v-stepper-content step="1" class="green-background">
          <v-form v-model="isEmailCheckValid">
            <v-card class="elevation-12 green-background">
              <v-card-text>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      name="firstName"
                      :label="this.$i18n.translate('First Name')"
                      id="firstName"
                      v-model="form.participant.user.firstName"
                      :rules="rules.firstName"
                      :error-messages="$error.getValidationError(errors, 'firstName')"
                      @input="$error.clearValidationError(errors, 'firstName')"
                      autocomplete="no"
                      class="required"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      name="lastName"
                      :label="this.$i18n.translate('Last Name')"
                      id="lastName"
                      v-model="form.participant.user.lastName"
                      :rules="rules.lastName"
                      :error-messages="$error.getValidationError(errors, 'lastName')"
                      @input="$error.clearValidationError(errors, 'lastName')"
                      autocomplete="no"
                      class="required"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <EmailField
                      v-bind="getEmailFieldProperties()"
                      v-model="form.participant.user.email"
                      :error-messages="$error.getValidationError(errors, 'email')"
                      @input="$error.clearValidationError(errors, 'email')"
                      :rules="rules.email"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-right">
                    <v-btn
                      @click.prevent="checkEmailAvailability"
                      type="submit"
                      :disabled="!isEmailCheckValid"
                      :loading="isLoading"
                      class="darker-green-background"
                    >
                      {{ $i18n.translate("Register") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-form>
        </v-stepper-content>

        <v-stepper-content step="2" class="green-background">
          <v-card class="elevation-12 green-background">
            <v-card-text>
              <div class="loading-progress mb-3" v-if="isLoading">
                <v-progress-circular :size="50" indeterminate color="primary" />
              </div>
              <div v-if="isRegistrationInProgress">
                <v-alert type="success" class="darker-green-background">
                  {{
                    $i18n.translate(
                      "Email already exists in the Channel Partner Program, your registration is not eligible in the Sony Bucks promotion. Please contact your Sony Account Manager for more information."
                    )
                  }}
                </v-alert>
              </div>
              <div v-else-if="isRegistrationComplete">
                <v-alert type="success" class="darker-green-background">
                  {{
                    $i18n.translate(
                      "Your registration was a success! Please check your email for further instructions."
                    )
                  }}
                </v-alert>
              </div>
              <div v-else-if="hasRegistrationError">
                <v-alert type="error">
                  {{ $i18n.translate("There was a problem during the registration") }}
                </v-alert>
              </div>
              <v-row>
                <v-col class="text-right">
                  <v-btn class="darker-green-background" @click.prevent="step = 1">
                    Go Back
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <!-- Final Notification -->
    <v-alert type="info" class="mt-3 darker-green-background alert-content">
      Once confirmed, you'll receive an email from
      <a href="mailto:ConsumerRebatesHQ@rebatesupport.com" class="alert-email"
        ><b>consumerrebateshq@rebatesupport.com</b></a
      >
      with Terms & Conditions and a link to the claim portal
    </v-alert>
  </div>
</template>

<script>
import EmailField from "@/gapp-components/components/fields/EmailField.vue";
import ValidationService from "@/gapp-components/services/validation.service.js";

import Vue from "vue";
export default {
  components: { EmailField },
  data() {
    return {
      rules: {
        firstName: ValidationService.generateTextValidationRules("First Name"),
        lastName: ValidationService.generateTextValidationRules("Last Name"),
        email: ValidationService.generateEmailValidationRules()
      },
      errors: {},
      step: 1,
      isLoading: false,
      isEmailCheckValid: false,
      isRegistrationCheckValid: false,
      isRegistrationInProgress: false,
      isRegistering: false,
      isRegistrationComplete: false,
      hasRegistrationError: false,
      form: {
        programKey: process.env.VUE_APP_PROGRAM_CCPR_KEY,
        clientKey: process.env.VUE_APP_CLIENT_KEY,
        participant: {
          address: {
            country: {
              name: "USA"
            }
          },
          status: {
            name: "ENR"
          },
          user: {
            firstName: "",
            lastName: "",
            email: "",
            username: ""
          },
          participantType: {
            participantTypeKey: 1030
          }
        },
        emailTemplateParticipantForm: {
          emailTemplateKey: "EMAIL_TEMPLATE_SONY_BUCK",
          context: {
            includeTemporaryPassword: false
          }
        }
      }
    };
  },
  watch: {
    step(newStep) {
      if (newStep === 1) {
        this.resetStepOne();
      }
    }
  },
  methods: {
    getEmailFieldProperties() {
      return {
        name: "email",
        label: this.$i18n.translate("Email"),
        id: "email",
        vModel: "form.participant.user.email",
        errorMessages: "$error.getValidationError(errors, 'email')",
        inputEvent: "$error.clearValidationError(errors, 'email')",
        autocomplete: "no",
        class: "required"
      };
    },
    resetStepOne() {
      this.isEmailCheckValid = false;
      this.isRegistrationCheckValid = false;
      this.isRegistrationInProgress = false;
      this.isRegistering = false;
      this.isRegistrationComplete = false;
      this.hasRegistrationError = false;
      this.isLoading = false;
      this.form.participant.user = {
        firstName: "",
        email: "",
        lastName: "",
        username: ""
      };
    },
    async checkEmailAvailability() {
      this.isLoading = true;
      this.errors = {};
      this.errorMessage = null;
      Vue.set(this, "errors", []);
      this.$api
        .get(`/api/participants/byUsername/${this.form.participant.user.email}/public`)
        .then(response => {
          if (response.data.some(participant => participant.programKey === process.env.VUE_APP_PROGRAM_CCPR_KEY)) {
            // Participant already registered with programKey SONY-CCPR
            this.isRegistrationInProgress = true;
            this.step = 2;
            this.isLoading = false;
          } else if (
            response.data.every(participant => participant.programKey !== process.env.VUE_APP_PROGRAM_CCPR_KEY)
          ) {
            // Register the participant with programKey SONY-CCPR
            let user = response.data[0].user;
            this.form.participant.user = user;
            this.form.emailTemplateParticipantForm.context.includeTemporaryPassword = false;
            this.submitRegistration().then(() => {
              this.step = 2;
            });
          } else {
            // Participant not registered
            this.form.emailTemplateParticipantForm.context.includeTemporaryPassword = true;
            this.isRegistering = true;
            this.step = 2;
          }
        })
        .catch(error => {
          console.error("Error checking username:", error);
          if (error.response && error.response.status === 404) {
            this.submitRegistration().then(() => {
              this.form.emailTemplateParticipantForm.context.includeTemporaryPassword = true;
              this.isRegistering = true;
              this.step = 2;
            });
          } else {
            this.hasRegistrationError = true;
            this.isLoading = false;
          }
        });
    },
    async submitRegistration() {
      this.isLoading = true;
      this.errors = {};
      var postForm = {};
      postForm.programKey = this.form.programKey;
      postForm.clientKey = this.form.clientKey;
      postForm.participant = this.form.participant;
      postForm.participant.email1 = this.form.participant.user.email;
      postForm.participant.user.username = this.form.participant.user.username
        ? this.form.participant.user.username
        : this.form.participant.user.email;
      postForm.participant.organization = {
        name: "",
        organizationType: {
          organizationTypeKey: "SB"
        }
      };
      postForm.emailTemplateParticipantForm = this.form.emailTemplateParticipantForm;

      this.$api
        .postWithCaptcha("/api/participants/enroll/public", postForm, null, "register")
        .then(() => {
          this.isRegistrationComplete = true;
        })
        .catch(error => {
          console.error("Registration Error", error);
          this.hasRegistrationError = true;
        })
        .finally(() => {
          this.isRegistering = false;
          this.isLoading = false;
        });
    }
  }
};
</script>

<style>
.loading-progress {
  display: flex;
  justify-content: center;
}

.v-main__wrap,
.program-details-card,
.program-details-text,
.green-background {
  background-color: #d1d5be !important;
}

.v-alert.darker-green-background.success,
.darker-green-background,
.v-alert.darker-green-background.info {
  background-color: #4a7a3e !important;
  color: #fff !important;
}

.hide-header {
  height: 0px !important;
  visibility: hidden;
}

html body .v-application .primary--text {
  color: #4a7a3e !important;
  caret-color: #4a7a3e !important;
}

.no-ellipsis {
  white-space: normal !important;
  overflow: visible !important;
  text-overflow: clip !important;
}

.v-list-item-title {
  word-wrap: break-word;
}

.alert-content {
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
}

.alert-email {
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
  white-space: normal;
  color: #fff !important;
  text-decoration: underline;
}
</style>
